import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { GatsbyImage } from 'gatsby-plugin-image';
import Special from '../components/Special';
import ContactUs from '../components/ContactUs';
import Divider from '../components/Divider';
import SEO from '../components/SEO';

export default function SpecialEventsPage({ path }) {
  const { tournament, halloween, summercamp } = useStaticQuery(graphql`
    query {
      tournament: file(relativePath: { eq: "tournament-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: NORTH }, )
        }
      }
      halloween: file(relativePath: { eq: "halloween.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: CENTER }, )
      }
      }
      summercamp: file(relativePath: { eq: "summer-camp.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: CENTER }, )
      }
      
    }
    }`);
  return (
    <>
      <SEO title="Events" description="Get to know Yuan Yen Do's Special Events, including tournaments, karate clinic, birthdays, and more!" image={summercamp} />
      <div className="px-4 pt-8 mx-auto mb-8 md:pt-16 max-w-7xl sm:px-6">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Get to know our</span>
            <span className="block text-blue-600">Special Events</span>
          </h1>
        </div>
      </div>
      {/* Tournaments */}
      <Divider title="Tournaments" />
      <section className="relative z-20 grid grid-cols-1 px-2 text-center md:grid-cols-2 bg-background-light xl:container xl:mx-auto md:text-left">
        <div className="z-30 rounded shadow-lg md:max-w-xl filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none">
          <GatsbyImage
            className="absolute inset-0 w-full h-full"
            imgClassName="object-top rounded"
            image={tournament.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>
        <div className="relative">
          <div className="mx-8 mt-8">
            <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">Karate Tournaments</p>
            <p className="mt-3 text-lg">
              Challenge your limits with a little friendly competition and compete alongside
              your peers! Yuan Yen Do Tournaments are a great opportunity for all ranks to put
              their skills to the test and apply their knowledge from class.
            </p>
            <div className="mt-8 mb-8 md:mb-16">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/events/tournaments"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
                >
                  <InformationCircleIcon className="w-6 h-6 pr-2" />
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Birthday Parties */}
      <Divider title="Birthday Parties" />
      <section className="relative z-20 grid grid-cols-1 px-2 text-center md:grid-cols-2 bg-background-light xl:container xl:mx-auto md:text-right">
        <div className="z-30 w-full rounded shadow-lg md:max-w-xl filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none md:hidden">
          <GatsbyImage
            className="absolute inset-0 right-0 w-full h-full"
            imgClassName="object-top rounded"
            image={halloween.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>
        <div className="relative">
          <div className="mx-8 mt-8 xl:pr-32 lg:pr-6 xl:ml-0">
            <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">Birthday Parties</p>
            <p className="mt-3 text-lg">
              You and your friends will participate in an hour-long class
              learning to test your new skills in our Gladiator Challenge, Punch and Kick
              Drills, and a Giant Ninja Obstacle Course! After class, there is a 30-minute
              party for cake and presents! Talk to your center leader about our different
              party options for your next birthday!
            </p>
            <div className="mt-8 mb-8 md:mb-16">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/events/birthday-parties"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
                >
                  <InformationCircleIcon className="w-6 h-6 pr-2" />
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="z-30 hidden w-full rounded shadow-lg md:max-w-xl filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none md:block">
          <GatsbyImage
            className="absolute inset-0 w-full h-full"
            imgClassName="object-top rounded"
            image={halloween.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>
      </section>
      {/* Summer Camps */}
      <Divider title="Summer Camps" />
      <section className="relative z-20 grid grid-cols-1 px-2 text-center md:grid-cols-2 bg-background-light xl:container xl:mx-auto md:text-left">
        <div className="z-30 rounded shadow-lg md:max-w-xl filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none">
          <GatsbyImage
            className="absolute inset-0 w-full h-full"
            imgClassName="object-top rounded"
            image={summercamp.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>

        <div className="relative">
          <div className="mx-8 mt-8">
            <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">Summer Camps</p>
            <p className="mt-3 text-lg">
              At our Summer Camps, we incorporate real-life
              situations into an exciting and active setting. All ranks
              and ages work together to learn simple and effective ways
              to deal with confrontations. We cover areas like shoving, ground
              fighting, and even weapon defense. Some of our member&#39;s
              favorite areas of our camps include animal tactics, takedown
              training, and agility courses!
            </p>
            <div className="mt-8 mb-8 md:mb-16">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/events/summer-camps"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
                >
                  <InformationCircleIcon className="w-6 h-6 pr-2" />
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Karate Clinics */}
      {/*
      <Divider title="Karate Clinics" />
      <section className="relative z-20 grid grid-cols-1 px-2 text-center md:grid-cols-2 bg-background-light xl:container xl:mx-auto md:text-left">
        <div className="z-30 rounded shadow-lg md:w-full filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none">
          <GatsbyImage
            className="absolute inset-0 w-full h-full"
            imgClassName="object-top rounded"
            image={tournament.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>
        <div className="relative">
          <div className="mx-8 mt-8">
            <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">Karate Clinics</p>
            <p className="mt-3 text-lg">
              Text about karate clinics
            </p>
            <div className="mt-8 mb-8 md:mb-16">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/events/karate-clinics"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
                >
                  <InformationCircleIcon className="w-6 h-6 pr-2" />
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="z-30 hidden max-w-xl rounded shadow-lg md:w-full filter max-h-96 inner-shadow drop-shadow-lg aspect-w-8 aspect-h-3 lg:aspect-none md:block">
          <GatsbyImage
            className="absolute inset-0 w-full h-full"
            imgClassName="object-top rounded"
            image={tournament.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Karate Tournament"
          />
        </div>
  </section> */}
      <Special path={path} />
      <ContactUs />
    </>
  );
}
